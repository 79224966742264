import { IAssessmentRequest } from "../interfaces/IAssessmentRequest";
import {t} from "../i18n/i18n";



export function CreateAssessment(data:IAssessmentRequest,study?:string): Promise<boolean>{
    const accessCodeKey = "accessCode"
    let url = `${AppSettings.ApiEndpointUrl}/CreateAssessment`

    if(study){
      url = `${AppSettings.ApiEndpointUrl}/CreateAssessment/${study}`
    }
    
    return fetch(url ,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
    }
  )
  .then(
    (response) => {
      if(!response.ok){
        response.json().then(err => {
          alert(err.message);
          if(localStorage.getItem(accessCodeKey)!==null){
            //Delete access code and reload window
            localStorage.removeItem(accessCodeKey)
            window.location.reload()
          }
          return;
        })
      } else {
          return response.json();
      }
    }
  )
  .then(
    (result) => {
      const {replacementDictionary, url} = result
      localStorage.setItem('replacementDictionary', JSON.stringify(replacementDictionary))
      window.location.replace(url);
      return true;
    }

  )
  .catch((err) => {
    if(!err.message) {
      alert(t("ERROR_CREATING_THE_ASSESSMENT"));
    }
    return false;
  })
}
