export interface IDropboxDisplay {
    visit: boolean;
    locale: boolean;
    study: boolean;
}

export const defaultDropboxDisplay = {
    visit: false,
    locale: false,
    study: false
}