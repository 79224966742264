import {useEffect} from 'react';
import { useI18n } from "../i18n/i18n";
import { IDropdownProps } from '../interfaces/props/IDropdownProps';

const Dropdown = (
    {
        selectName, placeholderTextKey, options, errorTextKey, change, value, 
        autoSelectSingleOption, hideFeatureFlag = false, defaultValue = "",defaultOptionValue="", display = true
    }: IDropdownProps
) => {
    const {t} = useI18n()

    if(hideFeatureFlag === true && defaultValue !== "")
        change(defaultValue)

    useEffect(()=>{
        if(autoSelectSingleOption){
            if(options.length === 1){
                change(options[0].value)
            }
        }

    },[autoSelectSingleOption, change, options])

    useEffect(()=>{
        if(defaultOptionValue!==""){   
            const defaultOptionIndex = options.findIndex(x=>(x.value).toLowerCase()===defaultOptionValue.toLowerCase())
            if(defaultOptionIndex!==-1){
                change(options[defaultOptionIndex].value)
            }
        }
    },[options])
    
    
    return (
        <div className={(!display || hideFeatureFlag) ? 'menu--item-container-hidden':'menu--item-container'}>
            <select
                required
                value={value}
                className='menu--item'
                name={selectName}
                onChange = {(e) => change(e.target.value)}
            >
                <option value="" disabled>{t(placeholderTextKey)}</option>
                {options.map(option => <option key={option.value} value={option.value}>{option.name}</option>)}
            </select>
            <div className='menu--error-notif'>
                {t(errorTextKey)}
            </div>
        </div>
    );
}

export default Dropdown;